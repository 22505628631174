import React, { useEffect } from 'react'
import { navigate } from 'gatsby'

import SEO from '../../components/SEO'
import { Form } from '../../_shared/components'
import { trackGAEvent } from '../../lib/ga'

const COMPLETED_STEP = {
  title: 'Bestilling bekreftet',
  close: true,
  items: [
    {
      item: 'text',
      value: 'Du mottar gavekortene på epost i løpet av noen minutter.',
    },
  ],
}

export default function Page() {
  useEffect(() => {
    const pendingPurchaseTime = Number(
      window.sessionStorage.getItem('pendingPurchaseTime') || '0',
    )

    // check if we have pending purchase data in session storage
    if (pendingPurchaseTime > new Date().getTime() - 60 * 60 * 24 * 1000) {
      // track purchase event
      trackGAEvent(
        JSON.parse(window.sessionStorage.getItem('pendingPurchase')),
        { event: 'purchaseCompleted' },
      )

      // clear session storage to avoid tracking it again on refresh
      sessionStorage.clear()
    } else {
      console?.warn(
        'Got to thank you page but had no (or too old (> 24h)) pendingPurchase data in sessionStorage',
      )
    }
  }, [])

  return (
    <>
      <SEO title="Gavekortkjøp gjennomført" />
      <Form
        step="completed"
        setStep={() => {}}
        handleBack={() => navigate('/gavekort')}
        navigation
        steps={{
          completed: COMPLETED_STEP,
        }}
      />
    </>
  )
}
