export const trackGAEvent = (...data) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(...data)
}

export const trackStepForGTM = step => {
  trackGAEvent({
    event: 'custom-event',
    'custom-event.category': 'Voucher redeem step',
    'custom-event.action': step,
  })
}
